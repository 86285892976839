export let MenuServerFetch: boolean = false;
export let ActiveRole: string = '';
export let UserRoles: any = new Array();


export interface INavData {
  name?: string;
  url?: string;
  roles?: any;
  iconComponent?:any;
}

function createNavItem(name:any, url:any, isActive:any, view:any, create:any, update:any, edit:any) {
  return {
    name,
    url,
    roles: {
      isActive,
      view,
      create,
      update,
      edit,
    },
    iconComponent: { name: 'cil-speedometer' },
  };
}

export const navItems: INavData[] = [
  createNavItem('Upcoming Follow Ups', '/dashboard', false, true, true, true, true),
  createNavItem('Wellness Consultations', '/wellness', true, false, false, false, false),
  createNavItem('Vital Checks', '/vitalchecks', true, false, false, false, false),
  createNavItem('Physio Consultations', '/pysioconsult', true, false, false, false, false),
  createNavItem('Single Resident Calls', '/singleresident', true, false, false, false, false),
  createNavItem('Wellness Camps', '/wellnesscamps', true, false, false, false, false)
];

export default navItems;

export function setMenuServerFetch(flag: boolean) {
  MenuServerFetch = flag;
}

export function resetUserRoles() {
  UserRoles = [];
}

export function setUserRoles(name: string) {
  UserRoles.unshift(name);
}

export function setActiveRole(name: string) {
  ActiveRole = name;
}

export function resetMaping() {
  navItems.forEach(element => {
    element.roles = {
      isActive: true,
      view: false,
      create: false,
      update: false,
      delete: false
    }
  });
}

export function rolemaping(permissions: any) {
 switch(permissions.module?.module_name) {
  case 'Upcoming Follow Ups':
    navItems[0].roles = {
      isActive: permissions.is_view,
      view: permissions.is_view,
      create: permissions.is_add,
      update: permissions.is_updated,
      delete: permissions.is_delete,
      approve: permissions.is_approve
    };
    break;
    case 'Wellness Consultations':   
    if (permissions.is_view) {
      navItems[0].roles.view = true;
      }   
      navItems[1].roles = {
        isActive: permissions.is_view,
        view: permissions.is_view,
        create: permissions.is_add,
        update: permissions.is_updated,
        delete: permissions.is_delete,
        approve: permissions.is_approve
      };
      break;
      case 'Vital Checks':        
        navItems[2].roles = {
          isActive: permissions.is_view,
          view: permissions.is_view,
          create: permissions.is_add,
          update: permissions.is_updated,
          delete: permissions.is_delete,
          approve: permissions.is_approve
        };
        break;
        case 'Physio Consultations':        
        navItems[3].roles = {
          isActive: permissions.is_view,
          view: permissions.is_view,
          create: permissions.is_add,
          update: permissions.is_updated,
          delete: permissions.is_delete,
          approve: permissions.is_approve
        };
        break;
        case 'Single Resident Calls':        
        navItems[4].roles = {
          isActive: permissions.is_view,
          view: permissions.is_view,
          create: permissions.is_add,
          update: permissions.is_updated,
          delete: permissions.is_delete,
          approve: permissions.is_approve
        };
        break;
        case 'Wellness Camps':        
        navItems[5].roles = {
          isActive: permissions.is_view,
          view: permissions.is_view,
          create: permissions.is_add,
          update: permissions.is_updated,
          delete: permissions.is_delete,
          approve: permissions.is_approve
        };
        break;
}
}