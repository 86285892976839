import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenService } from 'src/app/shared/services/TokenService.service';

@Injectable({
    providedIn: 'root'
  })

export class AuthService {

  constructor(
    private __http: HttpClient,
    private __token: TokenService

  ) { 
    
  }

  /**
   * 
   * @param body request body
   * @description login api 
   * @version v1
   */

  loginApi(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/account/login`, body);
  }

  refreshToken(body:object): Observable<any>{
    return this.__http.post(`${environment.API}/account/refreshToken`,body)
  }

  logoutStaff(): Observable<any> {
    return this.__http.get(`${environment.API}/account/logout`);
  }

  switchUser(user: any): void {
    this.__token.setToken(user.token);
    this.__token.setRefreshToken(user.refreshToken);
  }
  
}
