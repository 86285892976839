import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  token: any;
  refreshToken: any;

  setToken(token: string): void {
    this.token.next(token);
  }

  setRefreshToken(refreshToken: string): void {
    this.refreshToken.next(refreshToken);
  }

  getActiveUser(){
    const activeUser:any = sessionStorage.getItem('genie_active_user');    
    return JSON.parse(activeUser)
  }

}
