import { Component, Input, OnInit} from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/shared/services/TokenService.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { ModalConfirmComponent } from 'src/app/shared/modal-confirm/modal-confirm.component';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['default-header.component.scss']
})

export class DefaultHeaderComponent extends HeaderComponent implements OnInit{
  @Input() sidebarId: string = "sidebar";
  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)
  userName: any;
  staff_number: any;
  users: any[] = [];
  roleList: any = [];
  currentUser:any;
  bsModalRef?: BsModalRef;
  allUsers:any = [];
  activeUser: any;

  constructor(
    private classToggler: ClassToggleService,
    private __service: AuthService,
    private __token: TokenService,
    private router: Router,
    private __modalService: BsModalService,
    private __sideNavBarService : SidebarService

    ) {
    super();
  }

  ngOnInit(): void {
    this.toggleSideNav()
    this.currentUser = this.__token.getActiveUser();
    this.retrieveUsers()
  }

  toggleSideNav(){
    this.__sideNavBarService.toggleSideNav.subscribe((res:any)=>{
      if(res){
        this.toggleButton()
      }
    })
  }

  toggleButton(){
    document.getElementById('toggleButton')?.click()
  }

  retrieveUsers(): void {
    const users: any[] = JSON.parse(sessionStorage.getItem('users') || '[]');
    if (users.length > 0) {
      const activeUser = this.__token.getActiveUser();
      const usersData: any = sessionStorage.getItem('users');
      const users: any[] = JSON.parse(usersData);
      for (let i = 0; i < users.length; i++) {
        const user = users[i];  
        if (activeUser?.staff_id === user?.staff_id) {
          this.activeUser = user;
        } else {
          this.users.push(user);
        }
      }
    }
  }
  
  
  getToken(user:any){
    this.bsModalRef = this.__modalService.show(ModalConfirmComponent, {
      class: 'modal-md'
    });
    this.bsModalRef.content.closeBtnName = 'No';
    this.bsModalRef.content.submitBtnName = 'Yes';
    this.bsModalRef.content.subtitle = 'All Unsaved Changes Will Be Lost Do you want to Continue?';
    this.bsModalRef.content.title = 'Switch Account';
    this.bsModalRef.content.onClose.subscribe((result: any) => {
      if (result) {
        sessionStorage.setItem('genie_active_user',JSON.stringify(user))
        window.location.reload();   
        this.router.navigate(['/dashboard']);
      }
    });
  }

    onSignOut() {
      const users: any[] = JSON.parse(sessionStorage.getItem('users') || '[]');
      let activeUser: any = JSON.parse(sessionStorage.getItem('genie_active_user') || '{}');
      const activeUserIndex = users.findIndex((user) => user?.staff_id === activeUser?.staff_id);
      if (activeUserIndex !== -1) {
        users.splice(activeUserIndex, 1);
        if (users.length > 0) {
          activeUser = users[0];
        } else {
          activeUser = {}; 
          sessionStorage.clear();
          window.location.reload()
        }
        if (users.length > 0) {
          sessionStorage.setItem('users', JSON.stringify(users));
          sessionStorage.setItem('genie_active_user', JSON.stringify(activeUser));
          window.location.reload()
        }
      }
  }

  switchAccount(): void {
    this.router.navigate(['/login']);
  }

}
