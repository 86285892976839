<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
>
  <a style="margin: 10px">
    <c-sidebar-brand
      [brandFull]="{
        src: 'assets/images/cpclogo.jpg',
        width: 240,
        height: 70,
        alt: ''
      }"
      [brandNarrow]="{
        src: 'assets/images/cpclogo.jpg',
        width: 45,
        height: 30,
        alt: ''
      }"
    >
    </c-sidebar-brand>
  </a>

  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <c-sidebar-nav
      (click)="hideSidenavbar()"
      [navItems]="navItems"
      dropdownMode="close"
    >
    </c-sidebar-nav>
  </perfect-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  ></c-sidebar-toggler>
</c-sidebar>

<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <app-default-header
    class="mb-4 d-print-none header header-sticky"
    position="sticky"
    sidebarId="sidebar"
  ></app-default-header>
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
</div>
