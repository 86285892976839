import { CommonModule } from '@angular/common';
import { Component, NgModule,ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styles : [
    `
    .modal-header {
      border-bottom: 1px solid #d7d7d7 !important;
    }

    .modal-body,
    .modal-footer,
    .modal-header {
      padding: 1.75rem !important;
    }

    .modal .modal-body, .modal .modal-footer, .modal .modal-header {
      padding: 1.75rem;
  }

  .modal .modal-footer {
    border-top: 1px solid #d7d7d7;
  }

  .modal .modal-body, .modal .modal-footer, .modal .modal-header {
    padding: 1.75rem;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }

  .modal .close {
    color: #3a3a3a;
    text-shadow: none;
  }
    
  .custom-button{
    border:none;
    backkground:none;
  }
    `
  ]
})
export class ModalConfirmComponent {

  title?: string;
  subtitle?: string;
  closeBtnName?: string;
  submitBtnName?: string;
  isCancelEnbaled = true;
  public onClose: Subject<any> = new Subject();
  remove: FormGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
    status : new FormControl(null,)
  });
  removeFormSubmitted: boolean = false;

  statusList : any = [
    {
      name : 'Damaged/Lost',
      value : 'Damage/Lost'
    },
    {
      name : 'Unassign',
      value : 'Spare'
    }
  ]
  constructor(
    private _bsModalRef: BsModalRef,
    private __el: ElementRef,
  ) { }

  onSubmit() {
    if(this.title == 'Unassign Card'){
      this.removeFormSubmitted = true;
      if(this.remove.valid){
        let details = {
          close : true,
          values : this.remove.value
        }
        this.onClose.next(details);
        this._bsModalRef.hide();
      }
    }else{
      this.onClose.next(true);
      this._bsModalRef.hide();
    }
   
  }

  cancel() {
    this.onClose.next(false);
    this._bsModalRef.hide();
    
  }

}


@NgModule({
  entryComponents:[ModalConfirmComponent],
  declarations: [
    ModalConfirmComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    TooltipModule
  ],
  exports: [ModalConfirmComponent]
})
export class ModalConfirmModule { }