import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template:
   `
  <router-outlet></router-outlet>
  <div class="blur-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium"  color = "#fff" type = "ball-fussion" [fullScreen]="true">
    <p style="color: white">Loading...</p>
  </ngx-spinner>
</div>

   `
})
export class AppComponent implements OnInit {
  title = 'Genie Wellness'; 

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }
}
