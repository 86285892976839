<ng-container>
  <c-container [fluid]="true">
   <div class="custom-div"> 
    <button
    id="toggleButton"
    toggle="visible"
    cHeaderToggler
    [cSidebarToggle]="sidebarId"
    class="ps-1"
  >
    <svg cIcon name="cilMenu" size="lg"></svg>
  </button>
   </div>
    <c-header-nav>
      <img src="../../../../assets/images/logo-highres-black.png" alt="" width="80px" class="custom-image">
    </c-header-nav>
    <c-header-nav class="custom-div">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
</ng-container>


  <ng-template #userDropdown>
    <c-dropdown alignment="end" variant="nav-item">
      <button
        cButton
        color=""
        [caret]="false"
        cDropdownToggle
        class="py-0"
        style="display: flex"
      >
        <span
          class="circle custom-span"
        >
          {{ currentUser?.first_name.charAt(0) | uppercase }}
        </span>
        <div style="text-align: left">
          <p style="font-weight: bolder; margin-bottom: 0px">
            {{ currentUser?.first_name | uppercase }}
          </p>
          <p style="font-weight: bolder; margin-top: 0px">
            {{ currentUser?.staff_number }}
          </p>
        </div>
      </button>
      <ul
        cDropdownMenu
        class="pr-5 justify-content-end pr-5 justify-content-end dropdown-menu-right"
        style="width: 300px"
      >
        <li class="profile-list">
          <div>
            <ul *ngFor="let user of users" style="padding-left: 0px">
              <li>
                <h5 class="custom-heading">Switch To</h5>
              </li>
              <li
                (click)="getToken(user)"
                class="mt-3"
                style="
                  display: flex;
                  margin-bottom: 10px;
                  border-bottom: 1px solid #e4e4e4;
                  padding: 5px;
                  padding: 0px 12px 12px;
                "
              >
                  <span
                    class="circle custom-span"
            >
                    {{ user?.first_name.charAt(0) | uppercase }}
                  </span>
                <div
                  class=""
                  style="margin-left: 10px; cursor: pointer; font-weight: bolder"
                >
                  <div>
                    {{ user?.first_name + "" + user?.last_name | uppercase }}
                  </div>
                  <div>{{ user?.staff_number }}</div>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="profile-list"></li>

        <li style="margin-bottom: 10px; margin-top: 30px">
          <a
            cDropdownItem
            (click)="switchAccount()"
            style="
              padding-left: 14px;
              cursor: pointer;
              color: black;
            "
          >
            <img
              src="../../../../assets/img/users/adduser.png"
              width="20px"
              heigth="20px"
              alt="logo"
              style="margin-right: 10px"
            />
            Switch To...
          </a>
        </li>
        <li class="justify-content-end mb-3">
          <a
            cDropdownItem
            (click)="onSignOut()"
            style="
              padding-left: 14px;
              cursor: pointer;
            color: black;
          "
        >
          <img
            src="../../../../assets/img/users/exit.png"
            width="20px"
            heigth="20px"
            alt="logo"
            style="margin-right: 10px"
          />
          Sign Out
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>