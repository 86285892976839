import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { LoginComponent } from './views/pages/login/login.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { TokenInterceptor } from './_helpers/token.interceptor';
import { AuthGuard } from "src/app/_helpers/auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'wellness',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/wellness/wellness.module').then((m) => m.WellnessModule)
      },
      {
        path: 'vitalchecks',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/vital-checks/vital-checks.module').then((m) => m.VitalChecksModule)
      },
      {
        path: 'pysioconsult',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/pysio-consult/pysio-consult.module').then((m) => m.PysioConsultModule)
      },
      {
        path: 'singleresident',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/single-resident-calls/single-resident-calls.module').then((m) => m.SingleResidentCallsModule)
      },
      {
        path: 'wellnesscamps',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/wellness-camps/wellness-camps.module').then((m) => m.WellnessCampsModule)
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
    ]
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
