<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="10">
        <c-card-group class="login-card-group">
          <c-card class="text-white custom-background">
            <c-card-body class="text-center custom-image">
              <div class="login-logo">
                <img src="../../../../assets/images/logos.jpg" alt="" />
              </div>
            </c-card-body>
          </c-card>
          <c-card class="p-4 custom-login-wraper">
            <c-card-body class="custome-login">
              <form
                class="login-form"
                [formGroup]="loginForm"
                #form="ngForm"
                novalidate
                (ngSubmit)="onSubmit()"
                id="loginForm"
              >
                <h1>Login</h1>
                <div class="form-group">
                  <div
                    *ngIf="
                      this.loginForm.controls['staff_number'].invalid &&
                      loginForm.controls['staff_number'].touched
                    "
                    style="color: red"
                  >
                    Employee Number is required!
                  </div>
                  <input
                    class="form-control"
                    id="team-member"
                    type="text"
                    formControlName="staff_number"
                    max="99999999"
                    maxlength="15"
                    placeholder="Enter Employee Number!"
                    required
                  />
                </div>
                <div class="form-group">
                  <div
                    *ngIf="
                      this.loginForm.controls['password'].invalid &&
                      loginForm.controls['password'].touched
                    "
                    style="color: red"
                  >
                    Password is required!
                  </div>
                  <input
                    class="form-control"
                    id="team-password"
                    type="password"
                    formControlName="password"
                    placeholder="Enter Password!"
                    maxlength="25"
                    required
                  />
                </div>

                <button
                  cButton
                  class="px-4"
                  color="primary"
                  (click)="onSubmit()"
                >
                  Login
                </button>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
