import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  
export class RBACService {

  constructor(
    private __http: HttpClient
  ) { }

  /**
  * 
  * @description create Role api 
  * @version v1
  */

  createRole(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/roles/createRole`, body);
  }

  updateRole(body: object,id:any): Observable<any>{
    return this.__http.patch(`${environment.API}/roles/role/${id}`, body);
  }

  /**
* 
* @description get All Roles api 
* @version v1
*/

  getRoles(): Observable<any> {
    return this.__http.get(`${environment.API}/roles/getRoles`);
  }

  /**
* 
* @description get All Roles api 
* @version v1
*/

  removeRole(id: any): Observable<any> {
    return this.__http.delete(`${environment.API}/roles/role/${id}`);
  }

  /**
  * 
  * @description get All Roles api 
  * @version v1
  */

  getAllStaff(page: number, serachKey: string,filter:any): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/getAllStaff?search=${serachKey}&page=${page}&limit=50&filter=${filter}`);
  }

  getAllStaffByLocation(page: number, serachKey: string,filter:any): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/getAllStaffByLocation?search=${serachKey}&page=${page}&limit=50&filter=${filter}`);
  }

  /**
  * 
  * @description get All Roles api 
  * @version v1
  */

  getModules(): Observable<any> {
    return this.__http.get(`${environment.API}/modules/getModules`);
  }


  /**
  * 
  * @description get All Roles api 
  * @version v1
  */

  createModules(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/modules/createModules`, body);
  }

  /**
    * 
    * @description get All Roles api 
    * @version v1
    */

  createRbacRolePermision(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/user_staff/userstaffrolemap`, body);
  }


  /**
  * 
  * @description get All Roles api 
  * @version v1
  */

  createRbacRolePermisions(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/user_staff/createRbacRolePermision`, body);
  }



  /**
  * 
  * @description get All Roles api 
  * @version v1
  */

  userstaffrolemap(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/user_staff/userstaffrolemap`, body);
  }
  /**
  * 
  * @description get All Roles api 
  * @version v1
  */

  userstaffLocationmapCreate(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/user_staff/userstaffLocationmapCreate`, body);
  }

  /**
 * 
 * @description get All Roles api 
 * @version v1
 */

  staffUserUpdate(body: object, id: number | string): Observable<any> {
    return this.__http.put(`${environment.API}/user_staff/_staff_user_update/${id}`, body);
  }


  /**
   * 
   * @description get All Roles api 
   * @version v1
   */

  getRoleModulePermission(id: string | number): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/get_Role_Module_Permission/${id}`);
  }


  /**
    * 
    * @description get All Location master data api 
    * @version v1
    */

  getAllLocations(): Observable<any> {
    return this.__http.get(`${environment.API}/masters/getAllLocations`);
  }

  /**
   * 
  * @description get All Location master data api 
  * @version v1
  */

  getAllDepartments(): Observable<any> {
    return this.__http.get(`${environment.API}/masters/getAllDepartments`);
  }


  /**
    * 
    * @description get All Location master data api 
    * @version v1
    */

  getStaffLocationMap(id: string | number): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/get_user_staffs_location_map/${id}`);
  }


  getDepartmentByStaffId(id: string | number): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/getDepartmentByStaffId/${id}`);
  }


  /**
  * 
  * @description get all_roles_module_permission
  * @version v1
  */

  getAllRolesModulePermission(id: string | number): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/get_all_roles_module_permission/${id}`);
  }


/**
* 
* @description get staff Roles permission
* @version v1
*/

  staffRoles(): Observable<any> {
    return this.__http.get(`${environment.API}/user_staff/staffRoles`);
  }

  /**
* 
* @description Logout functions
* @version v1
*/

logoutStaff(): Observable<any> {
  return this.__http.get(`${environment.API}/account/logout`);
}

getStaffDetails(role_id:any){
  return this.__http.get(`${environment.API}/roles/StaffsForEachRole/${role_id}`);
}

}
