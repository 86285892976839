
<ng-container *ngIf="title != 'Unassign Card'">
<div class="modal-header" >
  <h2 class="modal-title pull-left" style="font-size: 1.6rem;">{{title}}</h2>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()" *ngIf="isCancelEnbaled" style="background: none; border: none; /* width: 34px; */">
    <span aria-hidden="true" style="font-size: 35px;">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <h3 style="text-align: center;font-size: 1.3rem;">{{subtitle}}</h3>
  </div>
</div>
<div class="modal-footer">
  <button class="btn  btn-primary" (click)="cancel()" style="background-color: white;color: #1d267d;">{{closeBtnName}}</button>
  <button class="btn" (click)="onSubmit()" style="background-color: #1d267d;color: white;">{{submitBtnName}}</button>
</div>
</ng-container>

<ng-container *ngIf="title == 'Unassign Card'">
<div class="modal-header" >
  <h2 class="modal-title pull-left" style="text-transform: capitalize;">{{title}}</h2>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()" *ngIf="isCancelEnbaled" 
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="remove" >
<div class="modal-body">
  <p>{{subtitle}}</p>
    <div class="container">
        

          <div class="row">
            <div class="form-group col-md-12" style="padding: 0px;">
                <label>Status</label>
                <ng-select 
                 [items]="statusList"
                 bindLabel="name"
                 bindValue = 'value'
                 placeholder="Select Status"
                 formControlName="status"
                 >
                 </ng-select>
            </div>
        </div>
            <div class="row">
                <div class="form-group col-md-12" style="padding: 0px;">
                    <label class="required">Comment</label>
                    <textarea type="textarea" class="form-control" name="comment" formControlName="comment" rows="2"></textarea>
                    <div *ngIf="remove.controls['comment'].invalid && removeFormSubmitted" class="invalid-tooltip">
                      {{remove.controls['comment'].errors?.['required'] ? 'Comment is required!' : 'Invalid Comment!'}}
                  </div>
                  
                </div>
            </div>
            
       
    </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="cancel()">{{closeBtnName}}</button>
  <button class="btn btn-primary" (click)="onSubmit()">{{submitBtnName}}</button>
</div>
</form>
</ng-container>