import { Component, OnInit } from "@angular/core";
import { SidebarService } from "src/app/shared/services/sidebar.service";
import { navItems } from "./_nav";
import { TokenService } from "src/app/shared/services/TokenService.service";
import { RBACService } from "src/app/shared/services/rbac.service";
import { setActiveRole, setUserRoles, resetUserRoles, rolemaping, setMenuServerFetch, resetMaping } from '../../containers/default-layout/_nav';
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = navItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };
  roleList:any = [];

  constructor(
         private __sideNavBarService: SidebarService,    
         private __auth:TokenService,
         private __rbac:RBACService,
         private router:Router
    ) {}

  ngOnInit(){
    resetUserRoles();
    resetMaping();  
    this.__rbac.staffRoles().subscribe((res:any)=>{
      res.data.staff_user_role_maps.map((role_map: any, index: number) => {
        setUserRoles(role_map.role.role_name);
        role_map.role.permissions.map((permissions: any) => {
          if (index == 0) {
            setActiveRole(role_map.role.role_name);
            rolemaping(permissions);
            this.roleList.push(permissions);
          }
        });
      });
      setMenuServerFetch(true);   
      this.navItems = this.navItems.filter((item: any) => item.roles.view);
    })
  }

  hideSidenavbar() {
    const tabletBreakpoint = 1023;
    const mobiePort = 767;
    const viewportWidth = window.innerWidth;
    const sideNavBar = document.getElementById("sidebar");
    if (viewportWidth <= tabletBreakpoint) {
      sideNavBar?.classList.add("hide");
      if (viewportWidth >= mobiePort) {
        this.__sideNavBarService.toggleSideNav.next(true);
      }
    }
  }
}
