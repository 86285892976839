import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TokenService } from 'src/app/shared/services/TokenService.service';
import { navItems,setActiveRole, setUserRoles, resetUserRoles, rolemaping, setMenuServerFetch, resetMaping } from '../../../containers/default-layout/_nav';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private router: Router,
    private __service: AuthService,
    private toastr: ToastrService,
    private __token:TokenService
  ) {
  }

  ngOnInit(): void {
    this.retrieveUsers()
  }
  buttonDisabled: boolean = false;
  buttonState: string = '';
  roleList: any = [];
  users: any[] = [];
  activeUser:any;
  loginForm: FormGroup = new FormGroup({
    staff_number: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  })

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.buttonDisabled = true;
      this.buttonState = 'show-spinner';
      this.__service.loginApi(this.loginForm.value).subscribe(res => {
        this.buttonDisabled = false;
        this.buttonState = '';
        if (res.status == 'OK') {
          const loggedInUser: any = res.data;          
          const users: any[] = JSON.parse(sessionStorage.getItem('users') || '[]');
          const isUserLogged = users.some(user => user?.staff_id === loggedInUser?.staff_id);
          if (isUserLogged) {
            sessionStorage.setItem('genie_active_user', JSON.stringify(res?.data));
            this.toastr.success('Login Successfully!', 'Success', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right'
            });
            this.router.navigate(['/dashboard']);
          } else {
            resetUserRoles();
            resetMaping();
            users.push(loggedInUser);  
            sessionStorage.setItem('users', JSON.stringify(users));
            const userCount = users.length - 1;
            const activeUser = users[userCount];
            sessionStorage.setItem('userCount', String(userCount));
            sessionStorage.setItem('genie_active_user', JSON.stringify(activeUser));
            res.data.staff_user_role_maps.map((role_map: any, index: number) => {
              setUserRoles(role_map.role.role_name);
              role_map.role.permissions.map((permissions: any) => {
                if (index == 0) {
                  setActiveRole(role_map.role.role_name);
                  rolemaping(permissions);
                  this.roleList.push(permissions);
                }
              });
            });
            setMenuServerFetch(true);
            for (const element of navItems) {
              if (element.roles.view) {
                this.router.navigate(['' + element.url], { state: element.roles });
                break;
              }
            }
          }
        }
      }, err => {
        this.buttonDisabled = false;
        this.buttonState = '';
        this.toastr.error('Invalid staff number or password!', 'Error', {
          timeOut: 3000,
        });
      });
    } else {
      console.log("Invalid Form Submission");
    }
  }
  
  retrieveUsers(): void {
    const users: any[] = JSON.parse(sessionStorage.getItem('users') || '[]');

    if (users.length > 0) {
      const activeUser = this.__token.getActiveUser();
      const usersData: any = sessionStorage.getItem('users');
      const users: any[] = JSON.parse(usersData);
  
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
  
        // Check if the user is the active user
        if (activeUser?.staff_id === user?.staff_id) {
          this.activeUser = user;
        } else {
          // Add the user to the users array
          this.users.push(user);
        }
      }
    }
  }
  
}