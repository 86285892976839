import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../../src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { TokenService } from 'src/app/shared/services/TokenService.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(
        private _authService: AuthService,
        private rout: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService,
        private __token: TokenService,


    ) { }

    /**
     *
     * @param request api request object
     * @param next handler
     * @description added token to each api call
     */

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {   
        let temp = this.router.url.includes('ticket-update');
        if (!temp) {
            this.spinner.show();
        }
        const userString = sessionStorage.getItem('genie_active_user');
        const user = userString ? JSON.parse(userString) : null;        
        if (user?.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }
    
        return next.handle(request).pipe(
            tap(() => temp == false ? 
             setTimeout(() => {
                this.spinner.hide();
              }, 2000): ''),
            catchError(err => {
                setTimeout(() => {
                    this.spinner.hide();
                }, 2000);
                if (err instanceof HttpErrorResponse && err.status === 500 && err.error.message == "jwt expired") {
                    return this.handleError(request, next);
                }
                return throwError(err);
            })
        );
    }

    private handleError(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);
          const userString = sessionStorage.getItem('genie_active_user');
          const user = userString ? JSON.parse(userString) : null;
      
          if (user?.refreshToken) {
            let data = {
              'refreshToken': user.refreshToken
            };
      
            return this._authService.refreshToken(data).pipe(
              switchMap((token) => {
                this.isRefreshing = false;
                let refreshToken = token.data.token;
                user.token = refreshToken;
                user.refreshToken = token.data.refreshToken;
                sessionStorage.setItem('genie_active_user', JSON.stringify(user));
                this.refreshTokenSubject.next(refreshToken);
                return next.handle(this.addToken(request, refreshToken));
              }),
              catchError((err) => {
                this.isRefreshing = false;
                setTimeout(() => {
                  this.spinner.hide();
                }, 2000);
                return throwError(err);
              })
            );
          }
        }
      
        return this.refreshTokenSubject.pipe(
          filter(token => token !== null),
          take(1),
          switchMap((token) => next.handle(this.addToken(request, token)))
        );
      }
      
    private addToken(request: HttpRequest<any>, token: string) {

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    ngOnDestroy() {
        this.refreshTokenSubject.unsubscribe();
    }

}
