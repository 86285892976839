export const environment = {
    production: false,
    API: 'https://devgenieapi.columbiacommunities.in/api', //DEV URL
    // API : 'https://sgapi.columbiacommunities.in/api', // STAGING URL
    //API: 'https://genieapi.columbiacommunities.in/api', //PRODUCTION
    exportLink : 'https://devgenieapi.columbiacommunities.in',//DEV URL
    // exportLink : 'https://sgapi.columbiacommunities.in',// STAGING URL
    //exportLink : 'https://genieapi.columbiacommunities.in',//PRODUCTION
    supportPal : 'https://sgt.columbiacommunities.in/en/admin/login', //DEV and Staging
    //supportPal : 'https://genietickets.columbiacommunities.in/en/admin/login', //PROD
    homeDeliveryAppUrl : 'https://drive.google.com/drive/folders/1-6nuyDey5K6s4BX69UqekYPRYtqbk5Ao',
    subHiddenBreakpoint: 1440,
    menuHiddenBreakpoint: 768,
    
  };
  